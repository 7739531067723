// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\wamp64\\www\\intact-lab-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("C:\\wamp64\\www\\intact-lab-gatsby\\src\\pages\\articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-events-js": () => import("C:\\wamp64\\www\\intact-lab-gatsby\\src\\pages\\events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("C:\\wamp64\\www\\intact-lab-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("C:\\wamp64\\www\\intact-lab-gatsby\\src\\pages\\jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-meet-js": () => import("C:\\wamp64\\www\\intact-lab-gatsby\\src\\pages\\meet.js" /* webpackChunkName: "component---src-pages-meet-js" */),
  "component---src-pages-team-js": () => import("C:\\wamp64\\www\\intact-lab-gatsby\\src\\pages\\team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

